<template>
  <div>
      <el-row>
            <el-col :span="10">
              
                <el-table
                    height="250"
                    :data="tableData_a"
                    border
                    style="width: 100%"
                    @selection-change="handleLeftSelectionChange">

                    <el-table-column
                    type="selection"
                    align="center"
                    width="40">
                  </el-table-column>
                    <el-table-column
                    prop="name"
                    :label="$t('devtable.xm')"
                    align="center"
                    >
                    </el-table-column>
                    <el-table-column
                    prop="number"
                   :label="$t('devtable.ghHH')"
                    align="center"
					width="110"
                    >
                    </el-table-column>
                    <el-table-column
                    prop="card_no"
                    :label="$t('devtable.kh')"
                    align="center"
					width="110"
					>                    
                    </el-table-column>
                </el-table>
            </el-col>
            
            
            <el-col :span="4" style="line-height: 250px;">
                <el-button type="primary"   @click="handRightToLeft" style="margin-left:12px;margin-right:4px"><i class="el-icon-arrow-left"></i></el-button>
                <el-button type="primary"  @click="handLeftToRight"><i class="el-icon-arrow-right"></i></el-button>
            </el-col>
            

            <el-col :span="10">
                <el-table
                    :data="tableData_b"
                    height="250"
                    border
                    style="width: 100%"
                    @selection-change="handleRightSelectionChange">

                    <el-table-column
                    type="selection"
                    align="center"
                    width="40">
                  </el-table-column>

                    <el-table-column
                    prop="name"
                    align="center"
                    :label="$t('devtable.xm')"
                    >
                    </el-table-column>
                    <el-table-column
                    prop="number"
                   :label="$t('devtable.ghHH')"
                    align="center"
					width="110"
                    >
                    </el-table-column>
                    <el-table-column
                    prop="card_no"
                    align="center"
					width="110"
                    :label="$t('devtable.kh')">
                    </el-table-column>
                </el-table>
            </el-col>
      </el-row>
  </div>
</template>

<script>
  export default {
    data() {
      return {
        tableData_a: [],
        tableData_b: [],
        selData_a: [],
        selData_b: [],
        UserInfo:''
      }
    },
    mounted () {
      this.UserInfo = JSON.parse(window.localStorage.getItem('user'));
      this.getEmployeeListWithDept()
    },
    methods:{
        getEmployeeListWithDept(dept_id,name){
            const params = {
                method: 'co.person.list',
                agent_id: this.UserInfo.agent_id,
                manager_id: this.UserInfo.manager_id,
                page_index: 1, 
                page_size: 1000,                
                dept_id: dept_id?dept_id:'',
                name: name?name:''
            }
            this.$serve(params).then(res => {
                console.log(res);
                this.tableData_a = res.data.data
                this.tableData_b = []
                this.selData_a = []
                this.selData_b = []
            })          
        },
        handRightToLeft(){
            this.selData_b.forEach(el=>{
                let index = this.tableData_b.indexOf(el)
                if(index != -1){
                  this.tableData_b.splice(index,1)
                  this.tableData_a.push(el)
                }
            })
            this.selData_b = [];
        },
        handLeftToRight(){
            this.selData_a.forEach(el=>{
                let index = this.tableData_a.indexOf(el)
                if(index != -1){              
                  this.tableData_a.splice(index,1)
                  this.tableData_b.push(el)
                }
            })
            this.selData_a = [];
        },

        handleLeftSelectionChange(val){         
         this.selData_a = val;
        },
        handleRightSelectionChange(val){
          this.selData_b = val;
          console.log(val)
        }
    }
  }
</script>

<style lang="less" scoped>

</style>

